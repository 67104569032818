import React, { useEffect, useState } from "react";
import { Amplify } from "aws-amplify";
import { Link } from "react-router-dom";
import { Authenticator } from "@aws-amplify/ui-react";
import "../style/Dashboard.css";
import "@aws-amplify/ui-react/styles.css";
import { Card, CardContent, Typography, CardMedia, Box, IconButton } from "@mui/material";
import OpenPlanner from "../components/common/OpenPlannerAPI.js";
import UploadCard from "../components/Courses/UploadCard";
import FileProgressSnackBar from "../components/Courses/FileProgressSnackBar";
import { CirclePicker } from 'react-color';
import ColorLensIcon from '@mui/icons-material/ColorLens'; 
import EditIcon from '@mui/icons-material/Edit';

function ClassCard(props) {
  const [currentColor, setCurrentColor] = useState(props.color); 
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const togglePicker = () => {
    setDisplayColorPicker(!displayColorPicker);
  };
  
  const handleEdit = (id) => {
    console.log(`Editing card with id: ${id}`);
    // Implement your edit functionality here
  };
  
  const handleColorChange = (color,id) => {
    setCurrentColor(color.hex);
    OpenPlanner.updateSyllabusColor(id, color.hex) 
  };
  return (
<div style={{ display: 'inline-block', margin: '20px' }}>
  <Card
    sx={{
      width: 345,
      height: 300,
      position: 'relative',
    }}
  >
    <div style={{ position: 'relative' }}>
      <CardMedia
        sx={{
          height: 200,
          backgroundColor: currentColor,
          position: 'relative',
        }}
        alt={props.name}
      >
        <IconButton
          style={{
            position: 'absolute',
            top: '10px',
            left: '10px',
            zIndex: 10,
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            color: currentColor,
            padding: '5px',
          }}
          onClick={togglePicker}
        >
          <ColorLensIcon />
        </IconButton>
      </CardMedia>
    </div>
    <Link to={props.link} style={{ textDecoration: 'none' }}>
      <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="h5" component="div" color="textPrimary">
          {props.name}
        </Typography>
        <IconButton
          style={{
            marginLeft: '10px',
            color: 'gray',
          }}
          onClick={() => handleEdit(props.id)}
        >
          <EditIcon />
        </IconButton>
      </CardContent>
    </Link>
    {displayColorPicker && (
      <div
        style={{
          position: 'absolute',
          top: '60px',
          left: '10px',
          zIndex: 20,
          backgroundColor: 'white',
          padding: '10px',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        }}
      >
        <CirclePicker color={currentColor} onChange={(color) => handleColorChange(color, props.id)} />
      </div>
    )}
  </Card>
</div>
  );
}

export default function Courses(props) {
  const [courses, setCourses] = React.useState([]);
  const [filesUploadState, setFilesUploadState] = React.useState([]);
  const [showFileProgress, setShowFileProgress] = React.useState(false);

  useEffect(() => {
    async function fetchData() {
      setCourses(await OpenPlanner.getCourses(false));
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      setCourses(await OpenPlanner.getCourses(false));
    }
    let interval = setInterval(() => {
      if (filesUploadState.length !== 0) {
        fetchData();
      }
    }, 1000);

    // Clear interval after 5 minute
    setTimeout(() => {
      clearInterval(interval);
    }, 1000 * 60 * 5);

    fetchData();
  }, [filesUploadState]);

  useEffect(() => {
    //Check if course in filesUploadState
    let copy = [...filesUploadState];
    filesUploadState.forEach((fileState) => {
      if (courses.find((course) => course.id === fileState.id)) {
        copy.find((file) => file.id === fileState.id).state = "Upload Complete";
      }
    });
    setFilesUploadState(copy);
  }, [courses]);
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "left",
      }}
    >
      {courses.map((course, index) => {
        return (
        <ClassCard
          key={index}
          name={course.name}
          color={course.color}
          link={`/Course/${course.id}`}
          id={course.id}
        />)
      }
      )}
      <UploadCard
        handleChange={async (file) => {
          setShowFileProgress(true);
          let fileState = {
            state: "Uploading",
            id: null,
            name: file.name,
          };
          let copy = [...filesUploadState, fileState];
          let id = copy.length - 1;
          setFilesUploadState(copy);
          let response = await OpenPlanner.uploadSyllabus(file);
          copy = [...filesUploadState];
          copy[id] = {
            state: "Processing Syllabus",
            id: response.syllabus_id,
            name: file.name,
          };
          setFilesUploadState(copy);
        }}
      />
      {showFileProgress && (
        <FileProgressSnackBar
          handleClose={() => {
            setShowFileProgress(false);
          }}
          filesUploadState={filesUploadState}
        />
      )}
    </Box>
  );
}
