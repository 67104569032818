import React, { useEffect } from "react";
import { Button, Box, Typography, IconButton } from "@mui/material";
import EventsList from "../components/Overview/EventsList.js";
import OpenPlannerAPI from "../components/common/OpenPlannerAPI.js";
import CourseLegend from "../components/Overview/CourseLegend.js";
import sortEventsByDay from "../components/common/sortEventsByDay.js";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import HomeIcon from "@mui/icons-material/Home";
import dayjs from "dayjs";

const formatDueDate = (dateString) => {
  const options = { weekday: "long", month: "long", day: "numeric" };
  const date = new Date(dateString);
  return new Intl.DateTimeFormat("en-US", options).format(date);
};

function eventsThisWeek(events, weekStartDate) {
  return events.filter((event) => {
    const eventDate = new Date(event.start);
    return (
      eventDate >= weekStartDate &&
      eventDate <= dayjs(weekStartDate).add(7, "day")
    );
  });
}

export default function Overview(props) {
  const [events, setEvents] = React.useState([]);
  const [courses, setCourses] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [currentWeek, setCurrentWeek] = React.useState(
    dayjs().startOf("week").toDate()
  );
  useEffect(() => {
    const fetchEvents = async () => {
      setCourses(await OpenPlannerAPI.getCourses());
      let data = await OpenPlannerAPI.getEvents();
      const today = new Date();

      data.sort((a, b) => {
        const dateA = new Date(a.start);
        const dateB = new Date(b.start);
        return Math.abs(dateA - today) - Math.abs(dateB - today);
      });

      if (data) setEvents(data);
      setLoading(false);
    };
    fetchEvents();
  }, []);

  const sortedEvents = events.toSorted((a, b) => {
    return new Date(a.start) - new Date(b.start);
  });

  const sortedEventsByDay = sortEventsByDay(
    eventsThisWeek(sortedEvents, currentWeek)
  );
  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <Box sx={{ padding: 3 }}>
      <Box display="flex" sx={{ marginBottom: 5 }}>
        <img
          style={{ marginTop: 7, marginBottom: 0, height: 35 }}
          src="Logo.png"
        ></img>
        <Button
          variant="outlined"
          sx={{ marginLeft: 2, marginRight: 2 }}
          onClick={() => {
            setCurrentWeek(dayjs().startOf("week").toDate());
          }}
        >
          Today
        </Button>
        <IconButton
          onClick={() => {
            setCurrentWeek(dayjs(currentWeek).subtract(7, "day").toDate());
          }}
        >
          <ArrowBackIosIcon />
        </IconButton>
        <IconButton
          onClick={() => {
            setCurrentWeek(dayjs(currentWeek).add(7, "day").toDate());
          }}
        >
          <ArrowForwardIosIcon />
        </IconButton>
        <Typography variant="h6" gutterBottom style={{paddingTop:'10px'}}>
          {currentWeek.toDateString().substring(4) +
            " - " +
            dayjs(currentWeek)
              .add(7, "day")
              .toDate()
              .toDateString()
              .substring(4)}
        </Typography>
      </Box>
      <CourseLegend courses={courses} />
      {sortedEventsByDay.length ? (
        sortedEventsByDay.map((event) => {
          return (
            <EventsList
              events={event}
              courses={courses}
              title={"Due on " + formatDueDate(event[0].start)}
            />
          );
        })
      ) : (
        <EventsList events={[]} courses={courses} title={`Due on this week`} />
      )}
    </Box>
  );
}
