import React, { useEffect, useRef } from "react";
import ToastCalendar from "@toast-ui/react-calendar";
import MenuIcon from '@mui/icons-material/Menu';
import {
  Box,
  Checkbox,
  Button,
  IconButton,
  Grid2 as Grid,
  Collapse,
} from "@mui/material";
import { Typography } from "@mui/material";
import dayjs from "dayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "@toast-ui/calendar/dist/toastui-calendar.min.css";
import OpenPlannerAPI from "../components/common/OpenPlannerAPI.js";
import Dialog from "@mui/material/Dialog";
import EditEvent from "../components/common/EditEvent.js";
import { Add, CompareSharp } from "@mui/icons-material";
import AddEvent from "../components/common/AddEvent.js";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export default function Calendar(props) {
  const calendarRef = useRef(null);
  const [editEvent, setEditEvent] = React.useState(null);
  const [addEvent, setAddEvent] = React.useState(null);
  const [events, setEvents] = React.useState([]);
  const [month, setMonth] = React.useState(new Date().getMonth());
  const [year, setYear] = React.useState(new Date().getFullYear());
  const [hiddenCourses, setHiddenCourses] = React.useState([]);
  const [courses, setCourses] = React.useState([]);
  const [calendarView, setCalendarView] = React.useState("month");
  const [displayLeftBar, setDisplayLeftBar] = React.useState(true);
  useEffect(() => {
    const fetchEvents = async () => {
      setEvents(await OpenPlannerAPI.getEvents());
      setCourses(await OpenPlannerAPI.getCourses());
    };
    fetchEvents();
  }, []);

  useEffect(() => {
    if (calendarRef.current) {
      const calendarInstance = calendarRef.current.getInstance();
      if (calendarInstance) {
        setYear(calendarRef.current.getInstance().getDate().getFullYear());
        calendarInstance.setOptions({
          template: {
            monthGrid(event) {
              console.log("Rendering event in monthGrid:", event);
              return `<span>${event.title || "No Title"}</span>`;
            },
            time(event) {
              return `<span>${event.title || "No Title"}</span>`;
            },
          },
        });
        calendarInstance.render(true);
      }
    }
  }, []);

  const handlePrevButton = () => {
    calendarRef.current.getInstance().prev();
    setMonth(calendarRef.current.getInstance().getDate().getMonth());
  };
  const handleNextButton = () => {
    calendarRef.current.getInstance().next();
    setMonth(calendarRef.current.getInstance().getDate().getMonth());
  };

  //Current time
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleExportCalendar = async () => {
    //Download the calendar from /calendar/export with axios
    OpenPlannerAPI.getExportICS().then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "calendar.ics");
      document.body.appendChild(link);
      link.click();
    });
  };

  function LeftBar() {
    return (
      <div
        style={{
          minWidth: "320px",
          width: "320px",
          background: "white",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Button
          variant="contained"
          sx={{ margin: 3, marginBottom: 1, background: "#670083" }}
          onClick={() => {
            setAddEvent({ start: new Date(), end: new Date() });
          }}
        >
          Add Event
        </Button>
        <Button
          onClick={handleExportCalendar}
          variant="contained"
          sx={{ margin: 3, marginTop: 1, background: "#670083" }}
        >
          Export Calendar
        </Button>
        <DateCalendar
          onChange={(e) => {
            calendarRef.current.getInstance().setDate(e);
            setMonth(e.month());
          }}
        />
        <div style={{ marginLeft: 10 }}>
          <Typography
            variant="h5"
            style={{
              fontFamily: "Roboto, sans-serif",
              fontWeight: 100,
              marginLeft: 10,
              marginBottom: 0,
              marginTop: "-30px",
            }}
          >
            My Classes
          </Typography>
          {courses.map((course) => {
            return (
              <div key={course.id} style={{ display: "flex" }}>
                <Checkbox
                  color={course.color}
                  checked={hiddenCourses.indexOf(course.id) === -1}
                  sx={{
                    color: course.color,
                    "&.Mui-checked": {
                      color: course.color,
                    },
                  }}
                  onClick={(e) => {
                    if (!e.target.checked) {
                      setHiddenCourses((hiddenCourses) => [
                        ...hiddenCourses,
                        course.id,
                      ]);
                    } else {
                      setHiddenCourses((hiddenCourses) =>
                        hiddenCourses.filter((id) => id !== course.id)
                      );
                    }
                  }}
                ></Checkbox>
                <div style={{ paddingTop: 9 }}>{course.name}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <>
      {" "}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div style={{ display: "flex" }}>
          <IconButton style={{height:50, width: 50, margin:"0px 5px 0px 5px"}} onClick={()=>{
            setDisplayLeftBar(!displayLeftBar);
          }}>
            <MenuIcon style={{height:30, width: 30}}/> 
          </IconButton>
          <img
          style={{ margin: 25, marginTop: 7, marginBottom: 0 ,  height:35}}
          src="Logo.png"
        ></img>
          <div style={{ width: "100%" }}>
            {editEvent != null && (
              <Dialog open={true} onClose={() => setEditEvent(null)}>
                <EditEvent
                  event={editEvent}
                  onSubmit={async () => {
                    setEditEvent(null);
                    window.location.reload();
                  }}
                />
              </Dialog>
            )}
            {addEvent != null && (
              <Dialog open={true} onClose={() => setEditEvent(null)}>
                <AddEvent
                  event={addEvent}
                  courses={courses}
                  onSubmit={() => {
                    window.location.reload();
                    setEditEvent(null);
                  }}
                />
              </Dialog>
            )}
            <div style={{ height: "50px", display: "flex", alignItems: "center" }}>
              <Button
                variant="outlined"
                sx={{marginLeft: 2, marginRight: 2}}
                onClick={() => {
                  calendarRef.current.getInstance().setDate(new Date());
                }}
              >
                Today
              </Button>
              <IconButton onClick={handlePrevButton}>
                <ArrowBackIosIcon />
              </IconButton>
              <IconButton onClick={handleNextButton}>
                <ArrowForwardIosIcon />
              </IconButton>

              <div
                style={{
                  display: "inline-block",
                  width: 150,
                }}
              >
                <Typography variant="h5" sx={{ marginLeft: 2 }} component="b">
                  {monthNames[month]}
                </Typography>
              </div>

              <div style={{ marginLeft: "auto" }}></div>
              <Button
                onClick={() => {
                  setCalendarView("day");
                }}
              >
                Day
              </Button>
              <Button
                onClick={() => {
                  setCalendarView("week");
                }}
              >
                Week
              </Button>
              <Button
                onClick={() => {
                  setCalendarView("month");
                }}
              >
                Month
              </Button>

              <div style={{ width: 100 }}></div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex" }}>
        <Collapse orientation="horizontal" in={displayLeftBar}>
          <LeftBar />
        </Collapse>
        <div style={{flex:1}}>
        <ToastCalendar
              ref={calendarRef}
              height="800px"
              view={calendarView}
              scrollToNow={true}
              navigation={true}
              useScrollDirection={true}
              usageStatistics={false}
              width="auto"
              onBeforeUpdateEvent={async ({ event, changes }) => {
                //Do not trust event or change time

                let correctEvent = await OpenPlannerAPI.getEvent(event.id);
                let newStart = new Date(correctEvent.start);
                let newEnd = new Date(correctEvent.end);

                // if start date are different, make sure distance to end is the same
                if (
                  changes.start &&
                  changes.start.d.getDate() != newStart.getDate()
                ) {
                  let diff = newEnd.getDate() - newStart.getDate();
                  newEnd.setDate(changes.start.d.getDate() + diff);
                  newStart.setDate(changes.start.d.getDate());
                } else {
                  if (changes.end) newEnd.setDate(changes.end.d.getDate());
                }

                //Cannot update to new month
                if (
                  new Date(correctEvent.start).getMonth() != newStart.getMonth()
                ) {
                  return false;
                }

                // // Update state
                setEvents((prevEvents) =>
                  prevEvents.map((evt) =>
                    evt.id === event.id
                      ? {
                          id: event.id,
                          start: newStart,
                          end: newEnd,
                          title: event.title,
                          body: event.body,
                          calendar: event.calendarId,
                          isCompleted: event.isPending,
                        }
                      : evt
                  )
                );

                await OpenPlannerAPI.updateEvent({
                  id: event.id,
                  start: newStart.toISOString(),
                  end: newEnd.toISOString(),
                  title: event.title,
                  body: event.body,
                  calendar: event.calendarId,
                  isCompleted: event.isPending,
                });
              }}
              onClickEvent={(e) => {
                console.log(e);
                setEditEvent({ calendar: e.event.calendarId, ...e.event });
              }}
              onSelectDateTime={(e) => {
                setAddEvent({ start: e.start, end: e.end });
              }}
              events={events
                .filter((event) => {
                  return hiddenCourses.indexOf(event.calendar) === -1;
                })
                .map((event) => {
                  return {
                    ...event,
                    isAllday: false,
                    category: "allday",
                    title: event.body,
                    calendarId: event.calendar,
                  };
                })}
              calendars={courses.map((course) => {
                return {
                  id: course.id,
                  name: course.name,
                  color: "black",
                  backgroundColor: course.color,
                  dragBgColor: course.color,
                  borderColor: course.color,
                };
              })}
            />
        </div>
        </div>
      </LocalizationProvider>
    </>
  );
}
